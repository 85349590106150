$mobile-breakpoint: 80em;

#container[data-theme="dark"] {
    --color-text: rgb(236, 240, 241); 
    --color-text-opposite: rgb(17, 17, 17); 
    --color-text-rgb: 236, 240, 241; 
    --color-text-darker: rgb(216, 210, 203);
    --color-text-darker-rgb: 216, 210, 203;
    --primary-color: rgb(57, 138, 185);
    --primary-color-rgb: 57, 138, 185;
    --primary-color-darker: rgb(28, 101, 140);
    --primary-color-darker-rgb: 28, 101, 140;
    --background: #0d1117;
    --background-rgb: 13,17,23;
    --foreground: rgb(22, 27, 34);
    --foreground-rgb: 22, 27, 34;

    --random-color-border: transparent;
}

#container {
    --color-text: rgb(20, 20, 20);
    --color-text-opposite: rgb(205, 205,205);
    --color-text-rgb: 20, 20, 20;
    --color-text-darker: rgb(50, 50, 50);
    --color-text-darker-rgb: 50, 50, 50;
    --primary-color: #398AB9;
    --primary-color-darker: #1C658C;
    --background: #ffffff;
    --foreground: #ffffff;
    --foreground-rgb: 255,255,255;
    --background-rgb: 255,255,255;

    --random-color-border: rgba(var(--color-text-rgb),0.3);
    --random-color-empty: transparent;
    --random-color-0: rgb(44, 187, 156);
    --random-color-0-rgb: 44, 187, 156;
    --random-color-1: rgb(57, 203, 117);
    --random-color-1-rgb: 57, 203, 117;
    --random-color-2: rgb(61, 154, 217);
    --random-color-2-rgb: 61, 154, 217;
    --random-color-3: rgb(154, 91, 179);
    --random-color-3-rgb: 154, 91, 179;
    --random-color-4: rgb(53, 73, 93);
    --random-color-4-rgb: 53, 73, 93;
    --random-color-5: rgb(240, 193, 41);
    --random-color-5-rgb: 240, 193, 41;
    --random-color-6: rgb(229, 125, 41);
    --random-color-6-rgb: 229, 125, 41;
    --random-color-7: rgb(236, 240, 241);
    --random-color-7-rgb: 236, 240, 241;
    --random-color-8: rgb(150, 165, 165);
    --random-color-8-rgb: 150, 165, 165;
    --random-color-9: rgb(214, 48, 49);
    --random-color-9-rgb: 214, 48, 49;

    --eruption-color-rgb: var(--random-color-9-rgb);
    --eruption-color-text: var(--random-color-9-text);
    --impact-color-rgb: var(--random-color-5-rgb);
    --impact-color-text: var(--random-color-5-text);

    --random-color-light: rgb(245, 245, 245);
    --random-color-dark: rgb(10, 10, 10);

    --random-color-empty: var(--color-text);
    --random-color-0-text: var(--random-color-dark);
    --random-color-1-text: var(--random-color-dark);
    --random-color-2-text: var(--random-color-light);
    --random-color-3-text: var(--random-color-light);
    --random-color-4-text: var(--random-color-light);
    --random-color-5-text: var(--random-color-dark);
    --random-color-6-text: var(--random-color-dark);
    --random-color-7-text: var(--random-color-dark);
    --random-color-8-text: var(--random-color-dark);
}

@for $i from 0 through 8 {
    .random-colored-#{$i} {
        background-color: var(--random-color-#{$i});
        color: var(--random-color-#{$i}-text);
    }
}