@import './variables.scss';
@import url('https://fonts.googleapis.com/css?family=Lato');

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  position: relative;
  overflow: hidden;
  margin: 0px;
  font-family: 'Lato';
}

#root {
    width: 100%;
    height: 100%;
    display: block;
  }

#container {
  color: var(--color-text);
  width: 100%;
  height: 100%;
  display: block;
}

#veil {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--background);
  opacity: 1;
  pointer-events: none;
  transition: opacity 1s ease-in-out;
}

button { 
    cursor: pointer;
}


#nextFrame {
}

canvas {
    cursor: grab;
    &:active {
        cursor: grabbing;
      }
}

.loader {
  position: absolute;
  bottom: 1em;
  left: 50%;
  z-index: 20;
  margin: auto;
  box-sizing: border-box;
  width: 3em;
  height: 3em;
  border-radius: 50%;
  border: 4px solid rgba(255, 255, 255, 0.1);
  transform-origin: 50% 50%;
  transform: perspective(200px) rotateX(66deg);
  animation: spinner-wiggle 1.2s infinite;
}
.loader:before,
.loader:after {
  content: "";
  position: absolute;
  inset: -4px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 4px solid #0000;
  animation: spinner-spin 1.2s cubic-bezier(0.6, 0.2, 0, 0.8) infinite,
    spinner-fade 1.2s linear infinite;
}
.loader:before {
  border-top-color: var(--color-text);
}
.loader:after {
  border-top-color: var(--primary-color);
  animation-delay: 0.4s;
}

@keyframes spinner-spin {
  100% { transform: rotate(360deg)}
}
@keyframes spinner-fade {
  25%, 75% { opacity: 0.1}
  50% { opacity: 1 }
}
    