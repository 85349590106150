@import "https://fonts.googleapis.com/css?family=Lato";
#container[data-theme="dark"] {
  --color-text: #ecf0f1;
  --color-text-opposite: #111;
  --color-text-rgb: 236, 240, 241;
  --color-text-darker: #d8d2cb;
  --color-text-darker-rgb: 216, 210, 203;
  --primary-color: #398ab9;
  --primary-color-rgb: 57, 138, 185;
  --primary-color-darker: #1c658c;
  --primary-color-darker-rgb: 28, 101, 140;
  --background: #0d1117;
  --background-rgb: 13, 17, 23;
  --foreground: #161b22;
  --foreground-rgb: 22, 27, 34;
  --random-color-border: transparent;
}

#container {
  --color-text: #141414;
  --color-text-opposite: #cdcdcd;
  --color-text-rgb: 20, 20, 20;
  --color-text-darker: #323232;
  --color-text-darker-rgb: 50, 50, 50;
  --primary-color: #398ab9;
  --primary-color-darker: #1c658c;
  --background: #fff;
  --foreground: #fff;
  --foreground-rgb: 255, 255, 255;
  --background-rgb: 255, 255, 255;
  --random-color-border: rgba(var(--color-text-rgb), .3);
  --random-color-empty: transparent;
  --random-color-0: #2cbb9c;
  --random-color-0-rgb: 44, 187, 156;
  --random-color-1: #39cb75;
  --random-color-1-rgb: 57, 203, 117;
  --random-color-2: #3d9ad9;
  --random-color-2-rgb: 61, 154, 217;
  --random-color-3: #9a5bb3;
  --random-color-3-rgb: 154, 91, 179;
  --random-color-4: #35495d;
  --random-color-4-rgb: 53, 73, 93;
  --random-color-5: #f0c129;
  --random-color-5-rgb: 240, 193, 41;
  --random-color-6: #e57d29;
  --random-color-6-rgb: 229, 125, 41;
  --random-color-7: #ecf0f1;
  --random-color-7-rgb: 236, 240, 241;
  --random-color-8: #96a5a5;
  --random-color-8-rgb: 150, 165, 165;
  --random-color-9: #d63031;
  --random-color-9-rgb: 214, 48, 49;
  --eruption-color-rgb: var(--random-color-9-rgb);
  --eruption-color-text: var(--random-color-9-text);
  --impact-color-rgb: var(--random-color-5-rgb);
  --impact-color-text: var(--random-color-5-text);
  --random-color-light: #f5f5f5;
  --random-color-dark: #0a0a0a;
  --random-color-empty: var(--color-text);
  --random-color-0-text: var(--random-color-dark);
  --random-color-1-text: var(--random-color-dark);
  --random-color-2-text: var(--random-color-light);
  --random-color-3-text: var(--random-color-light);
  --random-color-4-text: var(--random-color-light);
  --random-color-5-text: var(--random-color-dark);
  --random-color-6-text: var(--random-color-dark);
  --random-color-7-text: var(--random-color-dark);
  --random-color-8-text: var(--random-color-dark);
}

.random-colored-0 {
  background-color: var(--random-color-0);
  color: var(--random-color-0-text);
}

.random-colored-1 {
  background-color: var(--random-color-1);
  color: var(--random-color-1-text);
}

.random-colored-2 {
  background-color: var(--random-color-2);
  color: var(--random-color-2-text);
}

.random-colored-3 {
  background-color: var(--random-color-3);
  color: var(--random-color-3-text);
}

.random-colored-4 {
  background-color: var(--random-color-4);
  color: var(--random-color-4-text);
}

.random-colored-5 {
  background-color: var(--random-color-5);
  color: var(--random-color-5-text);
}

.random-colored-6 {
  background-color: var(--random-color-6);
  color: var(--random-color-6-text);
}

.random-colored-7 {
  background-color: var(--random-color-7);
  color: var(--random-color-7-text);
}

.random-colored-8 {
  background-color: var(--random-color-8);
  color: var(--random-color-8-text);
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: Lato;
  position: relative;
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100%;
  display: block;
}

#container {
  color: var(--color-text);
  width: 100%;
  height: 100%;
  display: block;
}

#veil {
  width: 100%;
  height: 100%;
  background-color: var(--background);
  opacity: 1;
  pointer-events: none;
  transition: opacity 1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

button {
  cursor: pointer;
}

canvas {
  cursor: grab;
}

canvas:active {
  cursor: grabbing;
}

.loader {
  z-index: 20;
  box-sizing: border-box;
  width: 3em;
  height: 3em;
  transform-origin: 50%;
  border: 4px solid #ffffff1a;
  border-radius: 50%;
  margin: auto;
  animation: 1.2s infinite spinner-wiggle;
  position: absolute;
  bottom: 1em;
  left: 50%;
  transform: perspective(200px)rotateX(66deg);
}

.loader:before, .loader:after {
  content: "";
  box-sizing: border-box;
  border: 4px solid #0000;
  border-radius: 50%;
  animation: 1.2s cubic-bezier(.6, .2, 0, .8) infinite spinner-spin, 1.2s linear infinite spinner-fade;
  position: absolute;
  inset: -4px;
}

.loader:before {
  border-top-color: var(--color-text);
}

.loader:after {
  border-top-color: var(--primary-color);
  animation-delay: .4s;
}

@keyframes spinner-spin {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner-fade {
  25%, 75% {
    opacity: .1;
  }

  50% {
    opacity: 1;
  }
}

/*# sourceMappingURL=presentation.071a007f.css.map */
